import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import {menuStyle2} from './style.js';

@customElement('secondary-menu')
export class SecondaryMenu extends LitElement {
    static get styles() {
        return menuStyle2
      }
    @property()
    menuItems: Array<any>;
    constructor() {
        super();
        this.menuItems = [
            {
                title: 'Home',
                link: '/',
                dropdown: false,
            },
            {
                title: 'About Us',
                link: '/about-us',
                dropdown: false,
            },
            {
                title: 'Our Services',
                link: '/services',
                dropdown: false,
            },
            {
                title: 'Get in Touch',
                link: '/contact-us',
                dropdown: false,
            },
            {
                title: 'Careers',
                link: '/careers',
                dropdown: false,
            },
        ];
    }

    render() {
        return html`

    <ul class="menu">
        ${this.menuItems.map((item) => {
            return html`
            <li class="menu-item">
              <a href="${item.link}">${item.title}</a>
            </li>
          `;
        })}
    </ul>

    `;
    }
}
