// docs for router https://github.com/thepassle/app-tools/blob/master/router/README.md

import { html } from 'lit';

if (!(globalThis as any).URLPattern) {
  await import("urlpattern-polyfill");
}

import { Router } from '@thepassle/app-tools/router.js';
import { lazy } from '@thepassle/app-tools/router/plugins/lazy.js';

// @ts-ignore
import { title } from '@thepassle/app-tools/router/plugins/title.js';

import './pages/app/app-home.js';

export const router = new Router({
    routes: [
      {
        path: '/',
        title: 'The Gems Family',
        render: () => html`<app-home></app-home>`
      },
      {
        path: '/about-us',
        title: 'About the Gems Family',
        plugins: [
          lazy(() => import('./pages/app-about/app-about.js')),
        ],
        render: () => html`<app-about></app-about>`
      },
      {
        path: '/services',
        title: 'About the Gems Family',
        plugins: [
          lazy(() => import('./pages/app-services/app-services.js')),
        ],
        render: () => html`<app-services></app-services>`
      },
      {
        path: '/contact-us',
        title: 'Get in Touch',
        plugins: [
          lazy(() => import('./pages/app-contact/app-contact.js')),
        ],
        render: () => html`<app-contact></app-contact>`
      },
      {
        path: '/careers',
        title: 'Careers',
        plugins: [
          lazy(() => import('./pages/app-careers/app-careers.js')),
        ],
        render: () => html`<app-careers></app-careers>`
      },
    ]
  });
