import { css } from 'lit';
export const styles = css`
header {
    display: flex;
    flex-flow: column wrap;
    justify-content: start;
    align-items: center;

    width: 100%;
    background-color: #99C71D50;
}
header menu {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: start;

    width: clamp(150px, 75vw, 75%);
    padding: 1.5%;
    margin: 0;
}
header span ul h1 {
    color: #F34213;
    font-size: 32px;
    text-align: center;

    margin: 0;
}
header menu a {
    display: block;
    padding: 0;
    margin: 0;
}
#logo {
    width: clamp(200px, 25vw, 25%);
}
header menu span {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
}
header menu span ul {
    display: flex;
    flex-flow: column wrap;
    justify-content: start;
    align-items: center;

    list-style: none;
    width: 100%;
    padding: 0;
    margin: 0;
}
header menu span ul a {
    color: #353535;
    font-size: 16px;
    text-align: center;

    align-self: center;
    text-decoration: none;
    cursor: pointer;
    transition: color .2s ease-in;
}
header menu span ul a:hover {
    color: #F34213;
}
header menu span ol {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-item: end;

    list-style: none;
    margin: 0;
    padding: 0;
}
header menu span ol li {
    padding: 1%;
    align-self: end;
}
header menu span img {
    width: 2vw;
    opacity: 1;
    transition: opacity .2s ease-in;
}
header menu span img:hover {
    opacity: .5;
}

header nav {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    background-color: #017593;
    
    list-style: none;
    width: clamp(350px, 100vw, 100%);
    height: clamp(350px, 25vh, 25%);
    padding: 1.5%;
    gap: 2%;
}
header nav li a {
    color: #fff;
    font-size: clamp(18px, 4vw, 24px);
    font-weight: 600;

    text-decoration: none;
    transition: color 0.8s ease
}
header nav li a:hover {
    color: #c0dd00;
}
`