import { css } from "lit";
export const menuStyle3 = css`
.menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    padding: 0;
    margin: 0;
    list-style: none;
}

.menu-item {
    position: relative;
    margin: .25rem;
}
.menu-item>a {
    color: #353535;
    font-size: 12px;
    font-weight: 600;

    position: relative;
    padding: 1rem;

    text-decoration: none;
}
`