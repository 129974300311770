import { LitElement, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import {menuStyle3} from './style.js';

@customElement('preamble-menu')
export class PreambleMenu extends LitElement {
    static get styles() {
        return menuStyle3
      }
    @property()
    menuItems: Array<any>;
    constructor() {
        super();
        this.menuItems = [
            {
                title: 'Privacy Policy',
                link: '/privacy-policy',
            },
            {
                title: 'Terms of Service',
                link: '/events',
            },
            {
                title: 'Cookies',
                link: '/cookies',
            },
            {
                title: 'Report an issue',
                link: '/report-an-issue',
            },
        ];
    }

    render() {
        return html`

    <ul class="menu">
        ${this.menuItems.map((item) => {
            return html`
            <li class="menu-item">
              <a href="${item.link}">${item.title}</a>
            </li>
          `;
        })}
    </ul>

    `;
    }
}
